import { debounce } from '@/shared'
import { Salespoint, useGetSalespointList } from 'simpleloan_api'
import { ref, computed } from 'vue'

export const useModel = () => {
  const { post, pending } = useGetSalespointList()

  const list = ref<Salespoint[]>([])

  const totalSize = ref<number | null>(null)

  const showMore = computed(() =>
    Boolean(list.value.length && totalSize.value && list.value.length < totalSize.value)
  )

  const onSearch = debounce((str: string | null) => {
    list.value = []
    getList(str)
  }, 500)

  const getList = (searchStr?: string | null) => {
    if (pending.value || (totalSize.value && list.value && list.value.length >= totalSize.value)) {
      return
    }

    const _filter = {
      mortgage_point: true,
      ...(searchStr && { title: `%${searchStr.trim()}%` })
    }

    post({
      search: {
        _filter,
        _start_index: list.value.length,
        _page_size: 15
      }
    }).then(res => {
      if (res?._data) {
        totalSize.value = res._data_total_size || null
        list.value.push(...res._data)
      }
    })
  }

  return {
    pending,
    showMore,
    onSearch,
    totalSize,
    list,
    getList
  }
}
