type DebounceFunction<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => void;

/**
 * Это функция-обертка, при которой обработка события откладывается на заданное время и сбрасывается при новом событии
 * @param fn Функция, которая будет выполняться
 * @param delay Время на которое откладывается выполнение функции
 * @returns Функцию, которую передали в fn, но обернутую в debounce
 */
export function debounce<T extends (...args: any[]) => any>(
  fn: T,
  delay: number
): DebounceFunction<T> {
  let timeout: ReturnType<typeof setTimeout>;

  return function(this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}