<template>
  <div class="flex gap-4 justify-center items-center">
    <List
      v-model:selected="leftListSelectedItem"
      class="w-full"
      :lazy="lazy"
      :items="leftListItems"
      :label="firstListLabel"
      :filter="filter"
      :loading="loading"
      :show-more="showMore"
      :item-label="itemLabel"
      :item-value="itemValue"
      @search="emits('search', $event)"
      @load-more="emits('loadMore')"
      @item-dblclick="item => handleLeftDblclick(item)"
      @show-more="emits('showMore')"
    />

    <div class="flex flex-col gap-2">
      <Button
        text
        rounded
        icon="pi pi-arrow-circle-right"
        class="text-slate-700 hover:bg-slate-300"
        severity="secondary"
        :pt="{ icon: { class: 'text-2xl' } }"
        :disabled="disableLeftHandle"
        @click="handleLeftItems()"
      />

      <Button
        text
        rounded
        icon="pi pi-arrow-circle-left"
        class="text-slate-700 hover:bg-slate-300"
        severity="secondary"
        :pt="{ icon: { class: 'text-2xl' } }"
        :disabled="disableRightHandle"
        @click="handleRightItems()"
      />

      <Button
        text
        rounded
        icon="pi pi-angle-double-right"
        class="text-slate-700 hover:bg-slate-300"
        severity="secondary"
        :pt="{ icon: { class: 'text-2xl' } }"
        :disabled="disableLeftHandle"
        @click="handleAllLeftItems()"
      />

      <Button
        text
        rounded
        icon="pi pi-angle-double-left"
        class="text-slate-700 hover:bg-slate-300"
        severity="secondary"
        :pt="{ icon: { class: 'text-2xl' } }"
        :disabled="disableLeftHandle"
        @click="handleAllRightItems()"
      />
    </div>

    <List
      v-model:selected="rightListSelectedItem"
      class="w-full"
      :items="rightListItems"
      :label="secondListLabel"
      :filter="filter"
      :item-label="itemLabel"
      :item-value="itemValue"
      @item-dblclick="handleRightDblclick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineModel, watch } from 'vue'
import { List } from '../List'
import Button from 'primevue/button'

type Item = Record<string, any>
type GetKeyFn = (item: unknown) => string

const props = withDefaults(
  defineProps<{
    items: Item[]
    lazy?: boolean
    filter?: boolean
    invalid?: boolean
    loading?: boolean
    showMore?: boolean
    disabled?: boolean
    itemLabel?: string | GetKeyFn
    itemValue?: string | GetKeyFn
    firstListLabel: string
    secondListLabel: string
  }>(),
  {
    itemLabel: 'title',
    itemValue: 'code'
  }
)

const emits = defineEmits<{
  loadMore: []
  showMore: []
  search: [string | null]
}>()

const selected = defineModel<(number | string)[]>('selected', { default: [], local: true })

const rightListItems = ref<Item[]>([])
const leftListItems = computed(() => {
  return props.items.filter(leftItem => {
    return !rightListItems.value.find(rightItem => getItemValue(rightItem) === getItemValue(leftItem))
  })
})

const leftListSelectedItem = ref<(number | string)[]>([])
const rightListSelectedItem = ref<(number | string)[]>([])

const disableLeftHandle = computed(() => false)
const disableRightHandle = computed(() => false)

watch(
  rightListItems,
  rightListItems => {
    selected.value = rightListItems.map(item => getItemValue(item))
  },
  { deep: true }
)

//** Обработчик переноса из левой части в правую*/
const handleLeftItems = () => {
  const items = props.items.filter(item => leftListSelectedItem.value.includes(getItemValue(item)))
  rightListItems.value.push(...items)
  leftListSelectedItem.value = []
}

//** Обработчик переноса из левой части в правую*/
const handleRightItems = () => {
  rightListItems.value = rightListItems.value.filter(
    rightItem => !rightListSelectedItem.value.includes(getItemValue(rightItem))
  )
  rightListSelectedItem.value = []
}

//** Обработчик переноса ВСЕХ items из левой части в правую*/
const handleAllLeftItems = () => {
  rightListItems.value.push(...leftListItems.value)
  leftListSelectedItem.value = []
  rightListSelectedItem.value = []
}

//** Обработчик переноса ВСЕХ items из правой части в левую*/
const handleAllRightItems = () => {
  rightListItems.value = []
  leftListSelectedItem.value = []
  rightListSelectedItem.value = []
}

const handleLeftDblclick = (item: Item) => {
  rightListItems.value.push(item)
}

const handleRightDblclick = (item: Item) => {
  rightListItems.value = rightListItems.value.filter(
    rightItem => getItemValue(rightItem) !== getItemValue(item)
  )
}

const getItemValue = (item: Item): number | string => {
  if (typeof props.itemValue === 'function') {
    return props.itemValue(item)
  }

  return item[props.itemValue]
}
</script>
